// полифилл для IE11
import 'whatwg-fetch';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import 'ts-polyfill';

/**
 * Actions
 */
import { default as carsActions } from './store/cars/actions';
import { default as carsSeenActions } from './store/carsSeen/actions';
import { favouritesActions } from './store/favourites';

export const actions = {
  ...carsActions, // TODO: Для совместимости, удалить после рефакторинга виджетов
  car: carsActions,
  carsSeen: carsSeenActions,
  favourites: favouritesActions,
};

/**
 * Selectors
 */
import * as carsSelectors from './selectors';
import { carsSeenSelectors } from './store/carsSeen';
import { favouritesSelectors } from './store/favourites';

export const selectors = {
  ...carsSelectors, // TODO: Для совместимости, удалить после рефакторинга виджетов
  cars: carsSelectors,
  carsSeen: carsSeenSelectors,
  favourites: favouritesSelectors,
};

/**
 * Hooks
 */
import { useSeens, useCarFromStoreOrApi } from './hooks/index';

export const hooks = {
  useSeens,
  useCarFromStoreOrApi,
};

/**
 * Other
 */
export { State as CarstockState } from './store';
export * as utils from './utils';
export {
  FilterValue,
  FilterValueDefault,
  FilterValueRange,
  Car,
  Ranges,
  Range,
  Field,
  Fields,
} from './store/cars/types';
export { useDispatch, useSelector, useStore } from './hooks';
export { default as carstockConnect } from './connect';
export { default, getStore } from './provider';

import { History } from 'history';
import { PatchedFiltersConfig } from './UiHelper';
import mergeWith from 'lodash/mergeWith';

import { Car } from '../store/cars/types';

/**
 * Параметры с приставкой default становятся помещаются в redux при инициализации приложения.
 * Предполагается что их можно переопределять во время выполнения приложения.
 */
type Config = {
  history?: History;
  filtersConfig?: PatchedFiltersConfig;
  carStockApi?: string;
  defaultPage?: number;
  defaultPerPage?: number;
  defaultSort?: string;
  thumbId?: number;
  carsRelated?: {
    count?: number;
    getReqParams?: (car: Car) => Record<string, any> | undefined;
  };
  carsSeen?: {
    count?: number;
  };
  favourites?: {
    defaultSort?: string;
    defaultPerPage?: number;
  };
  storagePrefix?: string;
  isBrowser: boolean;
};

type Params = keyof Config;

const config: Config = {
  defaultPerPage: 12,
  defaultPage: 0,
  defaultSort: 'price',
  thumbId: 5,
  favourites: {
    defaultSort: 'price',
    defaultPerPage: 1000,
  },
  isBrowser: typeof window !== 'undefined',
};

// function get(key: Params[]): Config[Params][];
// function get(key: Params): Config[Params];
// function get(key: Params | Params[]): Config[Params] | Config[Params][] {
function get(key: Params[]): any[Params][];
function get(key: Params): any[Params];
function get(key: Params | Params[]): any[Params] | any[Params][] {
  let param;

  if (typeof key === 'string') {
    param = config[key];
  } else if (Array.isArray(key)) {
    param = key.map((i) => config[i]);
  }

  return param;
}

function set(mod: Partial<Config>): void;
function set(key: Params, value: any): void;
function set(key: Partial<Config> | Params, value?: any): void {
  if (typeof key === 'object') {
    mergeWith(config, key, (objValue, srcValue, fieldKey) => {
      if (fieldKey === 'history') {
        return srcValue;
      }
    });
  } else {
    config[key] = value as never; // TODO: set всегда должен принимать объект
  }
}

function getConfigObject() {
  return Object.freeze({ ...config });
}

export default {
  get,
  set,
  getConfigObject,
};

export { Config };
